<template>
  <v-timeline-item>
    <v-card class="elevation-2 mr-4" :disabled="feedback.retracted">
      <v-list-item two-line>
        <v-list-item-icon class="my-5"><v-icon>tl-chat-bubble</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(`feedback.feedback${feedback.gym_id ? 'Gym' : 'App'}`) }}
            <span v-if="feedback.retracted"> retracted by user</span>
          </v-list-item-title>
          <v-list-item-subtitle v-text="moment(feedback.created_at).fromNow()" />
        </v-list-item-content>
        <v-list-item-action>
          <slot name="action" />
        </v-list-item-action>
      </v-list-item>
      <v-card-text class="text--primary pt-0">
        <div v-text="feedback.message" style="user-select: text" />
        <div v-if="feedback.reply">
          <v-list-item two-line>
            <v-list-item-icon><v-icon>tl-question-answer</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-t="'feedback.reply'" />
              <v-list-item-subtitle v-text="moment(feedback.replied_at).fromNow()" />
            </v-list-item-content>
          </v-list-item>
          <p class="ml-4 mb-0" v-text="feedback.reply" style="user-select: text" />
        </div>
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import Feedback from '@/models/Feedback'

export default {
  props: {
    feedback: { type: Feedback, default: () => {} },
  },
}
</script>
